import React, { Component } from 'react'
import { Container, Jumbotron, Spinner, Form } from 'react-bootstrap'
import { Check2 } from 'react-bootstrap-icons'
import { withRouter } from 'react-router-dom'
import { getOptions } from '../Classes/Base'
import Snippet from '../Classes/Snippet'
import Queryset from '../Classes/Queryset'
import { EditorState, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import '../css/Editor.css';
import 'draft-js/dist/Draft.css';
import RichTextEditor from '../Components/RichTextEditor';
import { DraftJSexportOptions, DraftJSimportOptions } from '../Helpers/draftHTMLhelpers';

const SAVE_DELAY = 1000

class SnippetDetail extends Component {
    state = {
        editorState: EditorState.createEmpty(),
        queryset: new Queryset(Snippet),
        options: undefined,
        language: undefined,
        updater: {}
    }

    async componentDidMount() {
        await this.state.queryset.one(this.props.match.params.id)
        const options = await getOptions(Snippet)
        const language = Object.keys(options.actions.POST.body.children)[0]
        const editorState = EditorState.createWithContent(stateFromHTML(this.state.queryset.object.body[language] || "", DraftJSimportOptions))
        this.setState({
            options,
            language,
            editorState
        })
    }

    async languageChanged(language) {
        const editorState = EditorState.createWithContent(stateFromHTML(this.state.queryset.object.body[language] || "", DraftJSimportOptions))
        this.setState({ editorState, language })
    }

    async delete() {

    }

    async valueChanged(event) {
        // RACE CONDITION FOR UPDATING BODY ATTRIBUTE FOR MULTIPLE LANGUAGES!
        const et = this.state.queryset.object
        const queryset = this.state.queryset
        et[event.target.id] = event.target.value
        queryset.object = et
        if (event.target.id === "hook") {
            event.target.value = null
        }
        await this.saveChanges(event.target.id)
        this.forceUpdate()
    }

    async saveChanges(name) {
        // Helper function for the timeout
        const storeChange = (name) => {
            const et = this.state.queryset.object
            const updater = this.state.updater
            const data = { id: et.id }
            data[name] = et[name]
            et.save(data).then(() => {
                updater[name] = undefined
                this.state.queryset.object = et
                this.setState(updater)
            })
        }
        let updater = this.state.updater
        if (updater[name] !== undefined) {
            clearTimeout(updater[name])
        }
        updater[name] = setTimeout(storeChange.bind(this, name), SAVE_DELAY)
        this.setState(updater)
    }

    onStyleButtonClick(ev) {
        const type = ev.target.dataset.type;
        this.editorChange(RichUtils.toggleInlineStyle(this.state.editorState, type));
    }

    editorChange = async (editorState) => {
        console.log(editorState)
        const last_change = editorState.getLastChangeType()
        if (last_change === null) {
            // I assume this means that the cursor position changed
            // this.forceUpdate()
            return
        }
        this.setState({ editorState })
        const event = {
            target: {
                id: "body",
                name: "body",
                value: {
                    [this.state.language]: stateToHTML(editorState.getCurrentContent(), DraftJSexportOptions)
                }
            }
        }
        this.valueChanged(event)
    }

    changeStyle = async (style) => {
        console.log(RichUtils.getCurrentBlockType(this.state.editorState))
        await this.editorChange(RichUtils.toggleBlockType(this.state.editorState, style))
    }

    render() {
        if (!this.state.queryset.object || !this.state.options) {
            return <Container>
                Načítáme data...
            </Container>
        } else {
            const snippet = this.state.queryset.object;
            return <Container>
                <Jumbotron>
                    <h1>Úryvek</h1>
                    <h2>{snippet.title}</h2>
                </Jumbotron>
                <h2>Text</h2>
                <div className="editor-wrapper border rounded">
                    <div className="p-2">
                        <RichTextEditor
                            editorState={this.state.editorState}
                            setEditorState={this.editorChange}
                            onChange={this.editorChange}
                            features={
                                {
                                    link: false,
                                    headers_all: true,
                                    load_changes: false
                                }} />
                        <input type="submit" className="invisible" />
                    </div>
                </div>
                <Form.Text>
                    {this.state.updater.body === undefined ? <><Check2 /> Změny uloženy</> :
                        <>
                            <Spinner animation="border" role="status" size="sm" className="mr-1">
                                <span className="sr-only">Saving...</span>
                            </Spinner>
                            Ukládání změn
                        </>
                    }
                </Form.Text>
                <h2>Zavěšení úryvku v systému: </h2>
                <Form.Group controlId="hook" className="mr-1">
                    <Form.Control as="select" custom value={snippet.hook || ""} onChange={(event) => { this.valueChanged(event) }} className="mt-2">
                        {this.state.options === undefined ? <option>Loading...</option> : this.state.options.actions.POST.hook.choices.map(i => {
                            return <option key={i.value} value={i.value}>{i.display_name}</option>
                        })}
                        <option value={""}>Žádný</option>
                    </Form.Control>
                    {this.state.updater.hook === undefined ? <><Check2 /> Změny uloženy</> :
                        <>
                            <Spinner animation="border" role="status" size="sm" className="mr-1">
                                <span className="sr-only">Ukládání ...</span>
                            </Spinner>
                            Ukládání změn
                        </>
                    }
                </Form.Group>
            </Container>
        }
    }
}

export default withRouter(SnippetDetail)
