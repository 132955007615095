import React from 'react'
import { InputGroup, Modal, Button, Row, Col, Card, Form } from 'react-bootstrap'
import { Alarm, CardText, GeoAlt, PersonSquare } from 'react-bootstrap-icons'
import { getOptions } from '../Classes/Base'
import MessageCenter from './MessageCenter'
import Location from '../Classes/Location'
import LoadingBanner from './LoadingBanner'
import moment from 'moment'
import FormField from './FormField'
import PlaceSelector from './PlaceSelector'


function LocationDatetimeField (props) {

    function valueChanged(e) {
        const type = e.target.type;
        const orig = moment(props.value)
        let result = moment()
        if (type === "date") {
            const value = moment(e.target.value);
            result = orig.isValid() ? orig.date(value.date()).month(value.month()).year(value.year()) : value
        }
        else {
            const value = e.target.value
            result = orig.hour(value.split(":")[0]).minute(value.split(":")[1])
        }
        props.onChange && props.onChange(result.format('YYYY-MM-DD HH:mm'))
    }

    const value = moment(props.value || "")

    return <>
        <InputGroup>
            {props.label && <InputGroup.Prepend>
                <InputGroup.Text>{props.label}</InputGroup.Text>
            </InputGroup.Prepend>}
            <Form.Control
                className={(props.errors && props.errors.length > 0) && "is-invalid"}
                type="date"
                value={value.format("YYYY-MM-DD")}
                onChange={e => valueChanged(e)}>
            </Form.Control>
            <Form.Control
                className={(props.errors && props.errors.length > 0) && "is-invalid"}
                type="time"
                value={value.format("HH:mm")}
                onChange={e => valueChanged(e)}>
            </Form.Control>
        </InputGroup>
        <Form.Text className="text-danger ml-1">{props.errors && props.errors.join(". ")}</Form.Text>
    </>
}

class LocationModal extends React.Component {
    state = {
        location: this.props.location,
        show: true,
        errors: {}
    }

    loadDefaultLocation() {
        const obj = {}
        if(this.state.options !== undefined && this.state.options.actions !== undefined && this.state.options.actions.POST !== undefined) {
            Object.keys(this.state.options.actions.POST).forEach(i => {obj[i] = ''})
        }
        obj.id = 0
        obj.checked = false
        obj.type = 'LOAD'
        obj.position = 0
        obj.fixed_window = false
        return obj
    }

    async componentDidMount() {
        this.setState({
            options: await getOptions(Location),
            
        }, () => {
            this.setState({location: (this.props.location || this.loadDefaultLocation())})
        })
    }

    async componentDidUpdate() {
        if (this.state.location === undefined) {
            this.setState({location: this.loadDefaultLocation()})
        }
    }

    onHide() {
        this.props.cancel && this.props.cancel()
    }

    valueChange(value, name) {
        const location = this.state.location
        location[name] = value
        if (name === "time" && !this.state.location.end_time) {
            this.valueChange(value, "end_time");
        }
        this.setState({location})
    }

    async save() {
        if (this.state.placeSelectorBlocking) {
            alert("Nejprve dokončete nastavení místa zastávky")
            return
        }
        const location = new Location(this.state.location)
        location.order = this.props.order.id
        await location.save()
        if (location.__messages && Object.keys(location.__messages).length) {
            this.setState({"errors": location.__messages})
        }
        if (location.__status >= 200 && location.__status <= 300) {
            this.props.saved && this.props.saved(location)
            this.setState({location: undefined})
        } else {
            MessageCenter.addMessage({
                title: 'Chyba při ukládání!',
                text: 'Data se bohužel nepovedlo uložit. Zkuste to prosím znovu, nebo kontaktujte podporu'
            })
            console.error(location.__status, location.__message)
        }
    }

    placeSelected(place) {
        const location = this.state.location
        location.place = place
        this.setState({location})
    }

    render() {
        return <Modal show={this.props.show} onHide={() => this.onHide()} size="xl" className="location-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    {this.state.location && this.state.location.id !== 0 ? "Editace zastávky" : "Nová zastávka"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { !this.state.location || !this.state.options ? <LoadingBanner /> : <React.Fragment>
                <Row>
                    <Col md={6}>
                        <Card style={this.state.placeSelectorBlocking ? {borderColor:"red"} : {}}>
                            <Card.Header className="d-flex justify-content-between">
                                <span  style={this.state.placeSelectorBlocking ? {color:"red"} : {}}><GeoAlt /> Místo</span>
                            </Card.Header>
                            <Card.Body>
                                    <PlaceSelector place={this.state.location.place}
                                        placeSelected={place => this.placeSelected(place)}
                                        blockingStateChanged={blocking => {this.setState({placeSelectorBlocking: blocking})}}
                                        />
                            </Card.Body>
                        </Card>
                        <Card className="mt-2">
                            <Card.Header className="d-flex justify-content-between">
                                <div><Alarm /> Datum a čas</div>
                                <div><Form.Check label="Fixní časové okno" id='fixed_window_input' checked={this.state?.location?.fixed_window || false} onClick={e => this.valueChange(e.target.checked, "fixed_window")}/></div>
                            </Card.Header>
                            <Card.Body>
                                <Form.Group>
                                        <LocationDatetimeField
                                            label="Začátek"
                                            errors={this.state.errors.time}
                                            value={this.state.location.time}
                                            onChange={value => this.valueChange(value, "time")}/>
                                        <LocationDatetimeField
                                            label="Konec"
                                            errors={this.state.errors.end_time}
                                            value={this.state.location.end_time}
                                            onChange={value => this.valueChange(value, "end_time")}/>
                                        { 
                                            this.state.location.end_time && 
                                                moment(this.state.location.time) > moment(this.state.location.end_time) &&
                                                <Form.Text className="text-warning">
                                                    <strong>
                                                        Zastávka má nastavený konec až po zahájení!
                                                    </strong>
                                                </Form.Text>
                                        }
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <FormField id="type" label="Typ zastávky" as="select" custom errors={this.state.errors['type']}
                            value={this.state.location.type} onChange={(e) => this.valueChange(e.target.value, "type")}>
                                {this.state.options && this.state.options.actions.POST.type.choices.map((item, key) => {
                                    return <option key={key} value={item.value}>
                                        {item.display_name}
                                </option>})}
                        </FormField>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <span><PersonSquare /> Kontakt</span>
                            </Card.Header>
                            <Card.Body>
                                {[["name", "Jméno"], ["phone", "Telefon"], ["code", "CODE"]].map((item, key) => {
                                    return <FormField id={item[0]} key={key} label={item[1]} placeholder={item[1]} errors={this.state.errors[item[0]]}
                                    value={this.state.location[item[0]]} onChange={e => {this.valueChange(e.target.value, item[0])}} />
                                })}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mt-2">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <span><CardText /> Poznámky</span>
                            </Card.Header>
                            <Card.Body>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={3} value={this.state.location.notes}
                                            onChange={(e) => this.valueChange(e.target.value, "notes")}>
                                    </Form.Control>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row></React.Fragment>}
                {this.state.location?.id === 0 && <div className='d-flex justify-content-end align-items-center'>
                    <div className='font-weight-bold mr-2'>Novou zastávku umístit: </div>
                    <Form.Check inline type="radio" name="position" value="100000" label="Na začátek"
                        checked={String(this.state.location?.position) === "100000"}
                        onChange={e => this.valueChange(e.target.value, "position")}
                        />
                    <Form.Check inline type="radio" name="position" value="0" label="Na konec"
                        checked={String(this.state.location?.position) === "0"}
                        onChange={e => this.valueChange(e.target.value, "position")}
                        />
                </div>}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={() => this.onHide()}>
                Zavřít
            </Button>
            <Button variant="primary" onClick={() => this.save()}>
                Uložit změny
            </Button>
            </Modal.Footer>
      </Modal>
    }
}

export default LocationModal