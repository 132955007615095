import React from 'react'
import { useHistory } from 'react-router-dom'
import Queryset from "../Classes/Queryset"
import { Button, Dropdown, Card, Spinner, Alert } from 'react-bootstrap'
import { GraphUp, Search } from 'react-bootstrap-icons'
import Order from '../Classes/Order'
import { getOptions } from '../Classes/Base'
import { OverlayComponent } from './OverlayComponent'
import MessageCenter from './MessageCenter'


/**
 * Renders an order summary component that displays statistics and options for filtering orders.
 *
 * @param {Object} filter - An object containing filters for the order queryset.
 * @property {Queryset} queryset - The queryset for Order objects.
 * @property {Array} selection - The selected Order objects.
 * @property {number} selectedOption - The selected option index from optionsList.
 * @property {Object} stats - The statistics for the selected orders.
 * @property {Object} filters - The filters for the order queryset.
 * @property {Array|null} options - The options for the order queryset.
 * @property {boolean} loaded - Indicates if the data is loaded.
 * @property {string} error - The error message if there was an error.
 * 
 * @return {JSX.Element} The rendered order summary component.
 * 
 * @author lotricek
*/
const OrderSummary = ({ filter }) => {
    const [selectedOption, setSelectedOption] = React.useState(0);
    const [stats, setStats] = React.useState(undefined);
    const [filters, setFilters] = React.useState(filter);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(undefined);
    const history = useHistory();

    const optionsList = [
        { title: "Poslední týden", filter: 7 },
        { title: "Poslední měsíc", filter: 30 },
        { title: "Poslední rok", filter: 365 },
    ];



    /**
     * React hook that fetches options for the Order class and updates the component state accordingly.
     * If an error occurs, logs the error, sets the options state to an empty array, sets the error state to the
     * error message, and adds a message to the MessageCenter with the error title and message.
     *
     * @return {Promise<void>} Promise that resolves when the options have been fetched and the component state has been updated.
     */
    React.useEffect(() => {
        setFilters(filter);
        handleSelectOption(0);
    }, [filter]);
    /**
     * Handles the selection of an option and updates the corresponding data.
     *
     * @param {any} selectedOption - The selected option to handle.
     * @return {Promise<void>} A Promise that resolves once the selection and data update is handled.
     */
    const handleSelectOption = async (selectedOption) => {
        setLoading(true);
        setSelectedOption(selectedOption);
        const queryset = new Queryset(Order);
        const filter = {
            ...filters,
            locations__time__gte: new Date(Date.now() - optionsList[selectedOption].filter * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
        };
        await queryset.filter(filter);
        setFilters(filter);

        try {
            const orders_ids = Object.values(queryset.objects).map((order) => order.id);
            const stats = await Order.stats(orders_ids);
            setStats(stats);
            setLoading(false);
        } catch (err) {
            setError(err.toString());
            console.error(err.toString());
            MessageCenter.addMessage({
                "title": "Nepodařilo se načíst souhrn objednávek",
                "message": "Bohužel se někde stala chyba, zkuste to znovu, pokud obtíže přetrvají kontaktujte podporu"
            })
            return;
        }
        
        setError();
    };


    /**
     * Generates a URL with the current window protocol, host, and search parameters
     * based on the provided filters.
     *
     * @return {string} The generated URL.
     */
    const getFilterURL = () => {
        const url = new URLSearchParams();
        Object.entries(filters).forEach(([key, value]) => url.append(key, value));
        return `/order-filter?${url.toString()}`;
    };

    return (
        <Card className="mb-2 pb-2">
            <Card.Header className="d-flex justify-content-between py-1">
                <div className='my-auto'>
                    <GraphUp />
                    {' '}
                    Přehled
                </div>
                <Dropdown onSelect={handleSelectOption}>
                    <Dropdown.Toggle size="sm" id="dropdown-basic">
                        {optionsList[selectedOption].title}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {optionsList.map((option, index) => (
                            <Dropdown.Item key={index} eventKey={index}>{option.title}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Card.Header>
            <Card.Body className='pb-0'>
                {!(stats?.count) ?
                    <span style={{
                        fontWeight: "bold"
                    }}>Žádné objednávky</span> :
                    error === undefined ? (
                        <>
                            <Card.Text className="d-flex justify-content-between py-1" as="div">
                                <div>Počet objednávek:</div>
                                <div>{(!loading && stats?.count >= 0) ? stats.count : <Spinner animation="border" role="status" size="sm" className="mb-1" />}</div>
                            </Card.Text>
                            <Card.Text className="d-flex justify-content-between py-1" as="div">
                                <div>Celkový obrat:</div>
                                <div className={`text-right ${!loading ? stats?.stats.sum > 0 ? "text-success" : "text-danger" : ""}`}>
                                    {(!loading && stats) ? `${stats.stats.sum.toFixed(2)} ${stats.home_currency?.label}` : <Spinner animation="border" role="status" size="sm" className="mb-1" />}
                                </div>
                            </Card.Text>
                            <OverlayComponent
                                text={<Button variant="link" className="text-dark p-0">
                                    <div variant={'secondary'} style={{
                                        textDecoration: "underline",
                                        textDecorationStyle: "dotted",
                                        fontWeight: "bold"
                                    }}>
                                        Další metriky
                                    </div>
                                </Button>}
                                title={<><Search /> <b>Jiné detaily objednávek</b></>}
                                placement={"left"}
                                trigger="click"
                                helpTopic="order_summary_details"
                                minWidth="300px"
                            >
                                <div className="w-100 d-flex justify-content-between py-1">
                                    <span>průměr:</span> <span>{(!loading && stats?.stats.avg) ? `${stats.stats.avg.toFixed(2)} ${stats.home_currency.label}` : <Spinner animation="border" role="status" size="sm" className="mb-1" />}</span>
                                </div>
                                <hr></hr>
                                <div className="w-100 d-flex justify-content-between py-1">
                                    <div className="my-auto py-1">
                                        <div>minimum</div>
                                        <div>{(!loading) ? stats?.records?.min ? `${stats.records.min.margin.toFixed(2)} ${stats.home_currency.label}` : "" : <Spinner animation="border" role="status" size="sm" className="mb-1" />}</div>
                                        <div>{stats?.records?.min ?
                                            <a href={`/order/${stats.records.min.id}`}>
                                                <span>
                                                    #{stats.records.min.code}
                                                </span>
                                            </a> : ""}
                                        </div>
                                    </div>
                                    <div className="my-auto py-1">
                                        <div>maximum</div>
                                        <div>{(!loading) ? stats?.records?.max ? `${stats.records.max.margin.toFixed(2)} ${stats.home_currency.label}` : "" : <Spinner animation="border" role="status" size="sm" className="mb-1" />}</div>
                                        <div>{stats?.records?.max ?
                                            <a href={`/order/${stats.records.max.id}`}>
                                                <span>
                                                    #{stats.records.max.code}
                                                </span>
                                            </a> : ""}
                                        </div>
                                    </div>
                                </div>
                            </OverlayComponent>
                            <Button onClick={() => {
                                history.push(getFilterURL());
                            }}
                                className='w-100' variant="light">Zobrazit objednávky</Button>
                        </>
                    ) : (
                        <>
                            <Alert variant="warning">Něco se pokazilo ... <br />
                                Podrobnosti: <br /> {error}
                            </Alert>
                        </>
                    )
                }
            </Card.Body>
        </Card >
    );
};

export default OrderSummary

