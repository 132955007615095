import React from "react";
import { Jumbotron, Container, Button } from "react-bootstrap";
import { Clipboard2, ExclamationTriangleFill } from "react-bootstrap-icons";
import fetchProxy from "../Helpers/fetchProxy";
import Account from "../Classes/Account";

class ErrorBoundary extends React.Component {
    state = {
        errorState: false,
        clicked: [false, false]
    }

    componentDidCatch(error, info) {
        this.setState({ errorState: true, error, info })
        const user = {
            id: Account.currentUser().id,
            username: Account.currentUser().username,
            is_superuser: Account.currentUser().is_superuser,
            is_staff: Account.currentUser().is_staff,
            is_admin: Account.currentUser().is_admin,
            permissions: Account.currentUser().user_permissions
        }
        const url = `${window.location.hostname}:${window.location.port}${window.location.pathname}`;
        const stacktrace = info.componentStack.replace(/\n\s+/g, "\n").split("\n");
        const errorData = {
            error: error.toString(),
            stacktrace,
            user,
            url
        };
        fetchProxy("/api/log", { method: "POST", body: JSON.stringify(errorData) })
    }

    copyToClipboard(data) {
        const info = JSON.stringify(data, null, 2).replace(/\\n/g, "\n");
        navigator.clipboard.writeText(info);
    }
    render() {
        return !this.state.errorState ?
            this.props.children :
            <Container>
                <Jumbotron>
                    <h1><ExclamationTriangleFill /> Chyba!</h1>
                    <p>Aplikace je stále ve vývoji. Zkuste to prosím znovu a jestli problém přetrvává, kontaktujte prosím podporu.</p>
                    <h2>Technické detaily</h2>
                    {["error", "info"].map((key, index) => <>
                        <h3>{key}</h3>
                        <pre key={index} className="border rounded bg-light" style={{ maxHeight: "20em", overflowY: "auto" }}>
                            <Button variant={this.state.clicked[index] ? "success" : "secondary"} className="float-sm-right m-2 sticky"
                                onClick={
                                    () => {
                                        this.copyToClipboard(this.state[key]);
                                        this.setState({ clicked: this.state.clicked.map((v, i) => i === index ? true : v) })
                                        setTimeout(() => this.setState({ clicked: [false, false] }), 1500);
                                    }}>{this.state.clicked[index] ? "Zkopírováno!" : <Clipboard2 />}</Button>
                            {JSON.stringify(this.state[key], null, 2).replace(/\\n/g, "\n")}
                        </pre>
                    </>)}
                </Jumbotron>
            </Container>
    }
}

export default ErrorBoundary