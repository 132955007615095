
/**
 * Export options for draft-js-import-html.
 * @type {Object}
 */
const DraftJSexportOptions = {
    /**
     * inlineStyleFn is a function that takes an array of styles and returns an
     * object with element and style properties that define how the text should
     * be rendered. The function is used to convert a draft.js style into an
     * object that can be used to render the text with a specific style. The
     * only style supported currently is 'color_<hex>', where <hex> is a
     * hexadecimal color code. The function returns null if the style is not
     * recognized.
     */
    inlineStyleFn: (styles) => {
        let key = 'color_';
        let color = styles.filter(value => value?.startsWith(key)).first();

        if (color) {
            return {
                element: 'span',
                style: {
                    color: `#${color.replace(key, '')}`,
                },
            };
        }
    },
};


/**
 * Export options for draft-js-export-html.
 * @type {Object}
 */
const DraftJSimportOptions = {
    /**
     * Converts inline style to a Style object.
     * @param {HTMLElement} element
     * @param {Object} options
     * @param {Style} options.Style
     * @returns {Style}
    */
    customInlineFn: (element, { Style }) => {
        if (element.style.color) {
            let rgbRegex = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
            let rgbMatch = element.style.color.match(rgbRegex);
            rgbMatch[1] = parseInt(rgbMatch[1], 10).toString(16).padStart(2, '0');
            rgbMatch[2] = parseInt(rgbMatch[2], 10).toString(16).padStart(2, '0');
            rgbMatch[3] = parseInt(rgbMatch[3], 10).toString(16).padStart(2, '0');
            return Style('color_' + rgbMatch[1] + rgbMatch[2] + rgbMatch[3]);
        }
    }
};

export { DraftJSexportOptions, DraftJSimportOptions };
