import React from 'react'
import { Dropdown, Table, Button, Modal, FormControl, InputGroup, Alert, Spinner, ButtonGroup } from 'react-bootstrap'
import { Gear, Trash, Plus, Dash, Search } from 'react-bootstrap-icons'
import Account from '../Classes/Account'
import '../css/ListView.css'
import transformField from '../Helpers/fieldTransforms'
import MessageCenter from './MessageCenter'


class ListView extends React.Component {
    state = {
        selection: this.props.selection || {},
        show_settings: false,
        columns: this.props.columns,
        settings_filter: ""
    }

    async componentDidMount() {
        /* Check for columns settings */
        this.loadColumns()
    }

    showSettings() {
        this.setState({show_settings: true})
    }

    hideSettings() {
        this.setState({show_settings: false})
    }

    toggleItemSelected(item) {
        const selection = this.props.selection
        if (item.id in this.props.selection) {
            delete selection[item.id]    
        }
        else {
            selection[item.id] = item
        }
        this.props.selectionUpdated && this.props.selectionUpdated(selection)
    }

    columnToggled(key, column) {
        const columns = this.state.columns
        const index = columns.findIndex(i => {return i.name === key})
        if (index > -1) {
            columns.splice(index, 1)
        }
        else {
            columns.push({
                name: key,
                label: column.label
            })
        }
        this.setState(columns)
        this.saveColumns()
    }

    columnMove(original_pos, target_pos) {
        const columns = this.state.columns
        columns.splice(target_pos, 0, columns.splice(original_pos, 1)[0])
        this.setState(columns)
        this.saveColumns()
    }

    async loadColumns() {
        //TODO, this can differ in different views! If this is used in a different component it should not share the same preferences
        const columns = JSON.parse(localStorage.getItem("listview__columns"))
        /* No preferences stored yet */
        if (columns === null) {
            return
        }
        if (!Array.isArray(columns) || !columns.length) {
            MessageCenter.addMessage("Bohužel se nepodařilo načíst Vaše nastavení sloupců")
        }
        this.setState({columns})
    }

    async saveColumns() {
        localStorage.setItem("listview__columns", JSON.stringify(this.state.columns))
    }

    renderSettingsContent() {
        if (!this.props.options) {
            return <span>Not loaded yet</span>
        }
        const columns = Object.entries(this.props.options.actions.POST).filter(i => {return i[1].label.includes(this.state.settings_filter)}).map(i => {
            const key = i[0]
            const field = i[1]
            return <div key={key}>
                {field.label} <input type="checkbox"
                    checked={this.state.columns.filter(i => {return i.name === key}).length > 0} onChange={() => {this.columnToggled(key, field)}}/>
            </div>
        })
        return <>
            <Alert variant="secondary">
                Zde nastavujete, které sloupce v přehledu uvidíte. Pokud uděláte změny, budou uloženy do mezipaměti a při příštím zobrazení stránky se opět použijí. <br /> Štítky je možné přetažením přeskupovat.
            </Alert>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3">
                    Vyhledávejte v sloupcích
                </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl id="basic-url" aria-describedby="basic-addon3" value={this.state.settings_filter} onChange={e => this.setState({settings_filter: e.target.value})}/>
            </InputGroup>
            <hr />
            {columns.length ? columns : <Alert variant="danger">Nic nenalezeno</Alert>}
        </>
    }

    renderTableHead() {
        const column_th = this.state.columns.map((col, i) => {
            return <th key={col.name}
                        draggable="true"
                        onDragStart={e => e.dataTransfer.setData("id", i)}
                        onDragOver={e => {e.preventDefault(); e.target.classList.add("dragged_over")}}
                        onDragLeave={e => e.target.classList.remove("dragged_over")}
                        onDrop={e => {e.preventDefault(); this.columnMove(e.dataTransfer.getData("id"), i); e.target.classList.remove("dragged_over")}}>
                            {col.label}
                        </th>
        })
        return <thead><tr>
                {column_th}
                <th className="text-right">
                    <Button variant="outline-primary" size="sm" onClick={() => this.showSettings()}><Gear /></Button>
                    <Modal show={this.state.show_settings} onHide={() => this.hideSettings()} size="lg">
                        <Modal.Header closeButton>
                        <Modal.Title>Nastavení přehledu</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.renderSettingsContent()}
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.hideSettings()}>
                            Zavřít
                        </Button>
                        <Button variant="primary" onClick={() => this.hideSettings()}>
                            Uložit změny
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </th>
            </tr></thead>
    }

    renderColumnField(value, column) {
        let res
        const transformations = this.props.transformations || {}
        if (!!transformations[column.name]) {
            res = transformations[column.name](value)
        }
        else {
            res = transformField(value, column)
        }
        return res;
    }

    deleteEntry(value) {
        if (!window.confirm("Chcete opravdu smazat tuto položku!")) {return}
        if (value.delete()) {
            this.props.entryDeleted && this.props.entryDeleted(value)
        }
    }

    renderTableBody() {
        if (this.props.updating) {
            return <tbody><tr>
                    <td className="text-center" colSpan={this.props.columns.length + 1}>
                        <Spinner animation="border" variant="info" /> <h3 className="ml-3 mb-0">Načítání výsledků</h3>
                    </td>
            </tr></tbody>
        }
        return <tbody>
            {this.props.entries.map((value, index) => {
                const row = <tr 
                        onClick={() => this.props.rowClicked && this.props.rowClicked(index)}
                        key={index}
                        style={{cursor: "pointer"}}
                    >
                    {this.state.columns.map(item => {
                        const res = this.renderColumnField(value[item.name], item)
                        typeof(res) === "function" && console.log(value['id'], item.name)
                        return <td key={item.name + "-" + value.id}>
                            {res}
                            {/*item.transform ? item.transform(value[item.name], options, value) : */}
                        </td>
                    })}
                    <td className="text-right" onClick= {e => e.stopPropagation()}>
                    <ButtonGroup>
                        <Button size="sm" variant="outline-secondary" onClick={() => this.toggleItemSelected(value)}>
                            {value.id in this.props.selection ? <Dash /> : <Plus />}
                        </Button>
                        <Dropdown>
                            <Dropdown.Toggle size="sm"></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => this.toggleItemSelected(value)}>
                                    {value.id in this.props.selection ? <><Dash /> Odebrat z výběru</> : <><Plus /> Přidat do výběru</>}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => this.props.navigate && this.props.navigate(value)}>
                                    <Search /> Zobrazit detail
                                </Dropdown.Item>
                                {Account.current().level > 0 && <Dropdown.Item className="text-danger" onClick={(event) => {event.stopPropagation(); this.deleteEntry(value)}}>
                                    <Trash /> Smazat
                                </Dropdown.Item>}
                            </Dropdown.Menu>
                        </Dropdown>
                    </ButtonGroup>
                    </td>
                </tr>
                return row 
            })}
            {this.props.entries.length < 1 && <tr>
                <td className="text-center" colSpan={(this.props.columns || this.state.columns).length + 1}>
                <div className="result-empty alert alert-danger">
                    <strong>Bez výsledků</strong><br />
                    <em>Zkuste hledat trochu jinak, třeba rozšířit filtrování</em>
                </div></td>
            </tr>}
        </tbody>
    }

    render() {
        return <div className="listview">
            <Table striped bordered hover>
            {this.renderTableHead()} 
            {this.renderTableBody()}
            </Table>
            <div className="selected">
                {Object.values(this.props.selection).map(item => {
                    return <div key={item.id} className="item" onClick={() => this.toggleItemSelected(item)}>
                        {item.code}
                    </div>
                })}
                {Object.values(this.props.selection).length === 0 ?
                    <p className="w-100 mb-0 text-center">Teď nemáte nic vybráno. <em>Použijte tlačíko "+" nebo menu "Výběr"</em></p> :
                    <div className="selection-length">Celkově vybráno: {Object.keys(this.props.selection).length}</div>}
            </div>
        </div>
    }
}

export default ListView