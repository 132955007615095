import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import Queryset from "../Classes/Queryset"
import Carrier from "../Classes/Carrier"
import EntityContactList from "../Components/EntityContactList"
import { getOptions} from "../Classes/Base"
import { Jumbotron, Spinner, Button, Row, Col, Container, Tabs, Tab, ListGroup, Alert } from 'react-bootstrap'
import { Trash, Truck } from 'react-bootstrap-icons'
import { OrderList} from '../Components/OrderList'
import { EntityForm } from '../Components/EntityForm'
import MessageCenter from '../Components/MessageCenter'
import LoadingBanner from '../Components/LoadingBanner'
import OrderSummary from '../Components/OrderSummary'



const SAVE_DELAY = 1000

class CarrierDetail extends Component {
    state = {
        queryset: new Queryset(Carrier),
        carrier : undefined,
        updater : undefined,
        options : undefined
    }

    async componentDidMount() {
      await this.state.queryset.one(this.props.match.params.id)
      if (this.state.queryset.status === 200) {
        this.setState({carrier: this.state.queryset.object})
        this.checkContacts(this.state.queryset.object)
      }
      const options = await getOptions(Carrier)
      this.setState({options})
    }

    async delete() {
        if (!window.confirm("Opravdu chcete smazat tento záznam?")) {
            return;
        }
        const result = await this.state.carrier.delete()
        if (result) this.props.history.push("/carrier/");
    }

    async valueChanged(name, value) {
        const carrier = this.state.carrier
        carrier[name] = value
        this.setState(carrier)
        await this.saveChanges(name)
    }

    async saveChanges(name) {
        // Helper function for the timeout
        const storeChange = (name) => {
            const carrier = this.state.carrier
            const data = { id: carrier.id }
            data[name] = carrier[name]
            carrier.save(data).then(() => {
                this.setState({updater: undefined})
                this.setState(carrier)
            })
        }

        let updater = this.state.updater
        if ( updater !== undefined) {
            clearTimeout(updater[name])
        }
        updater = setTimeout(storeChange.bind(this, name), SAVE_DELAY)
        this.setState({updater})
    }

    async checkContacts(carrier) {
        if (!Array.isArray(carrier.contact_set)) {
            return;
        }
        let has_informacni = false;
        carrier.contact_set.forEach(contact => {
            has_informacni ||= contact.is_informacni;
        })
        if (!has_informacni) {
            MessageCenter.addMessage({
                "title": "U dopravce chybí komunikační kontakt",
                "text": "Tento dopravce nemá definovaný komunikační účet. To znamená, že na objednávkách a dalších dokumentech s tímto dopravcem nebude uveden žádný kontakt. Použijte prosím záložku kontakty."
            })
        }
    }

    async icoAuto(data) {
        data = data[Object.keys(data)[0]]
        let key = Object.keys(data)
        let value = Object.values(data)
        const carrier = this.state.carrier
        
        for (let i=0;i<key.length;i++) {
            carrier[key[i]] = value[i]
            this.setState(carrier)
            await this.saveChanges(key[i])
        }

    }
  
    render() {
        if (this.state.carrier === undefined || this.state.options === undefined)  {
            return <LoadingBanner />
        }
        else {
            return (
                <Container>
                    <Jumbotron>
                        <h1>{this.state.carrier.name}</h1>
                        <Button className="d-block float-right" variant="danger" onClick={() => {this.delete()}}><Trash />Smazat</Button>
                        <h2>Detail dopravce #{this.state.carrier.id}</h2>
                    </Jumbotron>
                    <Row>
                        <Col md={8}>
                            <EntityForm excluded={["contact_set", 'vehicle_set']} entity={this.state.carrier} options={this.state.options} update={(name, value) => this.valueChanged(name, value)}/>
                        </Col>
                        <Col md={4}>
                            <div className='mb-2'>
                                {this.state.carrier.blacklist && <Alert variant="danger">
                                    <strong>Dopravce je na černé listině!</strong>
                                </Alert>}
                                <h3><Truck /> Vozidla</h3>
                                <ListGroup>
                                {this.state.carrier.vehicle_set.map(vehicle => {
                                    return <ListGroup.Item>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><strong>{vehicle.spz}</strong></td>
                                                </tr>
                                                {(vehicle.length || vehicle.width || vehicle.height) && <tr>
                                                    <td>D: {vehicle.length} V: {vehicle.height} Š: {vehicle.width}</td>
                                                </tr>}
                                                {vehicle.type && <tr>
                                                    <td>Typ: {vehicle.type}</td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                        
                                    </ListGroup.Item>
                                })}
                                </ListGroup>
                            </div>
                            <Tabs defaultActiveKey="contacts">
                                <Tab eventKey="contacts" title="Kontakty">
                                    <EntityContactList entity="carrier" id={this.state.carrier.id}/>
                                </Tab>
                                <Tab eventKey="orders" title="Poslední objednávky">
                                    <OrderSummary filter={{carrier: this.state.carrier.id}}/>
                                    <OrderList filter={{carrier: this.state.carrier.id}} title="Poslední objednávky" />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                    {this.state.updater && <div className="fixed-bottom">
                        <Container>
                            <Row>
                                <Col sm={12} md={8} lg={4} className="ml-auto bg-light d-flex p-2 mb-1 border rounded align-items-center justify-content-center">
                                    <Spinner animation="border" variant="info" /> <h3 className="ml-3 mb-0">Ukládání změn</h3>
                                </Col>
                            </Row>
                        </Container>
                    </div>}
                </Container>
            )
        }
    }
}

export default withRouter(CarrierDetail)
